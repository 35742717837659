import React from "react";
import { useQueryParam, StringParam } from "use-query-params";
import styled from "styled-components";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 500px;
  max-width: 100%;
  background-color: #fff;
`;

const FeedbackTitle = styled.h1`
	margin: 8px;
`;

const FeedbackText = styled.p`
	margin: 8px;
`;

const FeedbackButton = styled.a`
  width: 200px;
  padding: 8px;
  margin: 8px;
  background-color: #3a36ff;
  color:  #fff;
  border-radius: 16px;
  font-size: 16px;
  text-decoration: none;
`;

const FeedbackInvite = (props) => {
	const [code, setCode] = useQueryParam('code', StringParam);

	if (!code || !code.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i)) {
		return (
			<>
				<Navbar />
				<FeedbackContainer>
					<FeedbackTitle>Error</FeedbackTitle>
					<FeedbackText>kapot</FeedbackText>
				</FeedbackContainer>
				<Footer />
			</>
		)
	}

  return (
    <>
      <Navbar />
      <FeedbackContainer>
        <FeedbackTitle>Feedback Invitation Code</FeedbackTitle>
        <FeedbackText>Go back to the app to recieve the invite</FeedbackText>
        <FeedbackButton href={`posgez://feedback-invite?code=${code}`}>Open App</FeedbackButton>
      </FeedbackContainer>
      <Footer />
    </>
  );
};

export default FeedbackInvite;
